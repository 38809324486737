.text-medium {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.text-bold {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.text-regular {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.text-title-knewawe {
  font-family: "Knewawe", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 139.9%;
}

.text-title-roboto{
  color: $accent;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  font-weight: 600;
  text-transform: uppercase;
}


.text-bold-roboto{
    color: #0B0B0D;
    font-family: "Roboto", sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.text-bold-roboto-for-redeem {
    color: #0B0B0D;
    font-family: "Roboto", sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    
    @media all and (max-width: 768px){
        font-size: 22px;
    }
}

.text-name-roboto{
    color: #0B0B0D;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}


.blue-text-name-roboto {
    color: #377B6D;
    text-align: right;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    display: contents;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

  &.accent {
    color: $green-accent;
  }

  &.profile-link {
    margin-bottom: 5px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.bold-blue-text-name-roboto{
    color: $accent;
    
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    
    font-style: normal;
    font-weight: 700;
    line-height: normal;
   text-transform: uppercase;
}


.text-name-roboto-20{
    color: #0B0B0D;
    font-family: "Roboto";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

}


.white-text-roboto-20{
    color: #FFF;
    font-family: "Roboto";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.blue-roboto-14 {
  color: $accent;
  text-align: right;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.gray-roboto-14 {
  color: #0B0B0D;
  text-align: right;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  opacity: 0.6;
}

.gray-roboto-12{
    color: #0B0B0D;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.blue-roboto-12{
    color: $accent;
    font-family: "Roboto";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

  &.disabled {
    color: #0B0B0D;
    pointer-events: none;
  }
}
