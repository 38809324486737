$animation1-height: calc(55vh - 100px);
$animation2-height: calc(55vh - 150px);

.coin__right {
  position: absolute;
  //bottom: 200px;
  left: 150px;
  z-index: 1000;
  visibility: hidden;
}

.animation__coin-right {
  animation: coin-right-up 0.5s ease infinite;
  -webkit-animation: coin-right-up 0.5s ease;
}

@keyframes coin-right-up {
  0% {
    visibility: visible;
    transform: translateY(0);
  }
  100% {
    visibility: hidden;
    transform: translateY($animation1-height);
    right: 30px;
  }
}

.coin__left {
  position: absolute;
  bottom: 200px;
  left: 150px;
  z-index: 10000;
  visibility: hidden;
  color: greenyellow;
  font-size: 20px;
  font-weight: 600;
}
.giphy-embed {
  position: absolute;
  border-radius: 10000px;
}
.cat-coin {
  width: 100px;
  height: 20px;
  margin-bottom:100%;
  position: relative;
  border-radius: 10000px;
}
.animation__coin-left {
  animation: coin-cat-up 0.8s ease ;
  -webkit-animation: coin-cat-up 0.8s ease;
}


@keyframes coin-cat-up {
  0% {
    visibility: visible;
    transform: translateY(0);
  }
  100% {
    transform: translateY($animation2-height);
    left: 30px;
    visibility: hidden;
  }
}


.sleep-card {
  position: absolute;
  bottom: 300px;
  left: 140px;
  z-index: 1000;
  visibility: hidden;
}

.animation__sleep-card {
  animation: sleep-card-up 0.6s ease ;
  -webkit-animation: sleep-card-up 0.6s ease;
}

@keyframes sleep-card-up {
  0% {
    visibility: visible;
    transform: translateY(0);
  }
  100% {
    visibility: hidden;
    top: calc(97vh - 100px);
    left: 30px;
  }
}


.card__tasks--title {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 40px);
  margin-bottom: 15px;

  &.with-margin {
    margin-top: 20px;
  }
}

.card__tasks--title-accent {
  color: $accent;
  font-size: 24px;
}

.card__tasks--title-text {
  color: $white;
  font-size: 48px;
}
.card__plain-text {
  color: $white;
  font-size: 16px;
}

.cards__container, .cards__container--earnings, .cards__container--collectibles, .cards__container--community {
  width: calc(100vw - 40px);

  display: flex;
  flex-direction: column;
  gap: 8px;
  height: calc(100vh - 311px);

}

.cards__container--earnings {
  height: calc(100vh - 450px);
  @media screen and (max-height: 670px) {
    height: calc(100vh - 420px);
  }
}

.cards__container--community {
  height: calc(100vh - 250px);
  border-radius: 24px;
  background: #FFF;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
  padding: 20px;
}

.cards__container--collectibles {
  height: calc(100vh - 180px);
  border-radius: 24px;
  background: #FFF;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
  padding: 20px;
  //@media screen and (max-height: 670px) {
  //  height: calc(100vh - 180px);
  //}
}
.container-scrollable {
  overflow-y: auto;


  &::-webkit-scrollbar-thumb {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &.earnings {
    max-height: 20vh;

    @media screen and (max-height: 600px ){
      max-height: 17vh;
    }

  }
}

.card__task {
  background-color: rgba($black, 0.6);
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px 15px 15px;
}

.earning-card__task{
    display: flex;
    width: 100%;
    //border-radius: 12px;
    //background: #FFF;
    //box-shadow: 0px 12px 13px -16px rgba(0, 0, 0, 0.2);
    padding: 14px;
    //align-items: center;
    gap: 8px;
    justify-content: space-between;
}

.earning-card-block{
    display: flex;
    gap: 8px;
}
.earning__img {
  width: 44px;
  height: 44px;
  object-fit: cover;
  border-radius: 100px;
}

.earning__text-accent {
  color: $accent;
  text-align: right;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.earnings-card__row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.card__task--text {
  color: $white;
  font-size: 16px;
}
.card__task--text-accent {
  color: $accent;
  font-size: 16px;
}

.card__column-to-row {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }

  @media screen and (max-width: 380px) {
    max-width: 182px;
  }

  &.community {
    flex-direction: column;

  }
}
.cards-container--top {
  height: calc(100vh - 140px);
}

.redeem-cards-container {
  //height: 100%;
  display: flex;
  //width: 320px;

  padding: 20px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-radius: 24px;
  background: $white;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
}

.redeem-container {
  margin-top: auto;
  margin-bottom: 70px;
}

.redeem-title {
  color: $black-soft;

  font-family: Roboto, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin-bottom: 10px;
}

.redeem-cards-container2 {
  height: 100%;
  width: calc(100vw - 40px);
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: space-between;
  gap: 20px;
  margin: 0 auto;
    
  //position: absolute;
  //bottom: 110px;
  //left: 0;
  //right: 0;
}

.main-icon {
  width: 100px;
  height: 100px;
  path {
    fill: $violet
  }
}

.cards__redeem-button {
    display: flex;
    height: 56px;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 12px;
    background: $accent;
    
    color: #FFF;
    font-family: "Roboto";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
}

.cards__redeem-button:hover{
    color: #000;
    transition: 0.5s ease;
}

.wallet-adapter-button {
  width: calc(100vw - 120px) !important;
}

.wallet-adapter-button-trigger {
  background-color: transparent !important;
}


.redeem-text-btn{
    color: #000;
    font-family: "Work Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; 
    letter-spacing: -0.64px;
    align-items: center;
}

.counter-of-ppurs{
    color: #FFF;
    text-align: center;
    font-family: "Work Sans";
    font-size: 96px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.purr-counter-text{
    color: #B479FF;
    font-family: "Work Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin: 0 auto;
}

.wrapper-of-ppurs{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.gap-of-wrapper-of-ppurs{
    //gap: 120px;
    gap: 30px;
}

.form-for-crypto-wallet{
   // display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    background: #FFF;
}

.label-for-crypto-wallet{
        color: #B479FF;
        text-align: right;
        font-family: "Work Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 102%;
        letter-spacing: -0.48px;
}

.input-for-crypto-wallet{
    background: none;
    outline: none;
    border: none;
    width: 85%;
    
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    flex: 1 0 0;
    overflow: hidden;
    color: #0B0B0D;
    text-overflow: ellipsis;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

  &.profile {
    //width: 280px;
  }
}

.input-error-text {
  color: $red;
}

.wrapper-for-crypto-wallet{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 10px;
}

.crypto-icon, .set-wallet{
    position: absolute;
    right: 20px;
   // bottom: 15px;
    bottom: 15px;

}

.set-wallet {
  path {
   // fill: $accent
  }
}

.top-container {
 height: calc(100vh - 140px);
    
 padding: 10px 27px;
 display: flex;
 flex-direction: column;
 //justify-content: center;
 align-items: center;
 height: 100vh;
 width: 100%;
 position: relative;
 overflow: hidden;
}


.cards__bottom--main {
  width: 100vw;
  display: flex;
  align-items: end;
  justify-content: end;
  gap: 10px;
  z-index: 0;
}

.card__bottom--main {
  width: 40px;
  height: 40px;
  margin-right: 20px;
  margin-bottom: 20px;
  background-color: $white;
  border-radius: 16px;
  color: $white;
  font-size: 20px;
  font-weight: 600;
  //line-height: 23.44px;
  font-family: sans-serif, Roboto;
  display: flex;
  //flex-direction: row;
  align-items: center;
  justify-content: center;
  //gap: 10px;
  //z-index: 1000;
  //margin-bottom: 1px;
}


.picklezone-card__task--text{
  color: $black-soft;

  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.picklezone-card__timer {
  color: $accent !important;

  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
.picklezone-card__task {
  color: $black-soft;

  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.small-card-task-text{
  color: $accent;

  //text-align: right;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.small-card-task-text--accent {
  color: $blue-dim;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}

.claim-text-card{
    color: $blue-dim;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
    text-align: center;

  &.daily {
    color: $white;
  }
}

.picklezone-claim-row{
    display: flex;
    align-items: center;
    gap: 8px;
    flex-direction: row;
    margin-top: 12px;
}

.new-picklezone-card__task{
    display: flex;
    width: 100%;
    padding: 10px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    
    //border-radius: 12px;
    background: #FFF;
    //box-shadow: 0px 12px 13px -16px rgba(0, 0, 0, 0.2);

  &.new-picklezone-card__task--daily {
    background-color: $white;

    .picklezone-card__task--text {
      color: $white !important;
    }
    .small-card-task-text {
      color: $white !important;
    }
  }
}

.picklezone-card__column-to-row{
    display: flex;
    flex-direction: column;
}

.picklezone-card__row {
  display: flex ;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.bckgrnd-token{
    background: url('../../assets/bckgrnd.png');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
}

.disabled--bright {
  pointer-events: none;
  cursor: default;
  visibility: hidden;
  opacity: 0;
}
.picklezone-card--img-paddle {
  background-color: rgba(245, 245, 245, 1);
  border-radius: 8px;
  height: 90px;
  //height: 60px;
  &.picklezone-card--img-paddle--grayscale {
    filter: grayscale(100%);
  }
}

/// Earnings page

.cards-container--referral {
  margin-bottom: 60px;

  @media screen and (max-height: 670px) {
    margin-bottom: 30px;
  }
  display: flex;
  //min-width: 320px;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  //gap: 10px;
  border-radius: 24px;
  background: #FFF;

  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);

  &.ahead {
    z-index: 1000;
  }

}

.earning-card__img {
  width: 44px;
  height: 44px;
  border-radius: 100px;
  object-fit: cover;
}


.friends-card {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.friends-card--title {
  color: $black;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  opacity: 0.4;
}
.friends-card--description {
  font-size: 14px;
  font-family: Roboto, sans-serif;
  font-weight: 600;
  color: $blue-dim;
}

.referral-task-container {
  max-width: calc(100vw - 40px);
  min-height: 70px;
  height: 70px;
  padding: 16px 24px 16px 24px;
  gap: 8px;
  border-radius: 12px;
  border: 2px solid $blue-dim;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
  margin-left: 20px;
}

.referral-task-container--claimable {
  background-color: $blue-dim;
}

.referral-task-container--text {
  font-size: 16px;
  font-family: Roboto, sans-serif;
  font-weight: 600;
  color: $black-soft;

  @media screen and (max-width: 400px) {
    max-width: 156px;
  }
  &.referral-task-container--text-claimable {
    color: $white;
  }
}


.referral-task-container--invite {
  font-size: 14px;
  font-family: Roboto, sans-serif;
  font-weight: 600;
  color: $blue-dim;
  cursor: pointer;

  @media screen and (max-width: 400px) {
    max-width: 108px;
  }

  &.referral-task-container--invite-claimable {
    background-color: $white;
    padding: 10px 15px 10px 15px;
    gap: 10px;
    border-radius: 4px;
  }
}


.referrals-number--container {
  display: flex;
  flex-direction: row;
  width: 100vw;
  align-items: center;
  justify-content: space-between;
  margin-top: 44px;
  @media screen and (max-height: 670px) {
    margin-top: 20px;
  }
}

.referrals-number--number {
  font-family: Roboto, sans-serif;
  font-size: 80px;
  font-weight: 700;
  text-align: center;
  color: $black-soft;
}

.referrals-number--dim {
  font-family: Roboto, sans-serif;
  font-size: 56px;
  font-weight: 700;
  line-height: 65.63px;
  text-align: center;
  color: $black-soft;
  opacity: 0.2;
}

.referrals-number--space {
  width: 46px;
  height: 1px;
  background-color: $black-soft;
  opacity: 0.2;
}


.referral__person-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.person-info__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(2px);
  z-index: 100;
}

.referral__cards-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  z-index: 30;
  margin-bottom: 9px;
  
  @media screen and (max-width: 300px) {
    flex-direction: column;
  }

  &.ahead {
    z-index: 1000;
  }
}
.referral__card {
  display: flex;
  padding: 14px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 14px;
  background: #FFF;

  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
}

///////////////////////
.token-block{
    margin: 0 auto;
    position: absolute;
    top: 90px;
    left: 50%;
    transform: translateX(-50%);
}


.success-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: $white;
  background-image: url("../../assets/background-success.png");
  width: 100vw;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.success-img {
  width: 250px;
  height: 325px;
  object-fit: cover;
  @media screen and (max-height: 600px) {
    width: 200px;
    height: 200px;
  }
}

.success-img--container {
  position: relative;
  margin-top: 40%;

  @media screen and (max-height: 600px) {
    margin-top: 26%;
  }
}
.success-card {
  background-color: $white;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  //gap: 16px;
  padding: 16px;
  margin-top: auto;
  margin-bottom: 160px;
  box-shadow: 0 0 80px 0 #00000066;
  align-items: center;

  @media all and (min-width: 360px){
    margin-bottom: 180px;
  }
}
.success-card--title {
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  color: $accent;
}
.success-card--text {
  color: $black-soft;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 12px;
  margin-bottom: 24px;
}

.success-card__small-text {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: $accent;
}

.success-label {
  padding: 9px 15px 9px 15px;
  gap: 30px;
  border-radius: 3000px;
  box-shadow: 0 0 80px 0 #B1219A99;
  background-color: #b1219a;
  border: 3px solid #FFFFFF66;
  color: $white;
  position: absolute;
  top: 60%;
  left: 30%;
}

.success-btns {
  display: flex;
  flex-direction: row;
  gap: 3px;
  z-index: 1000;
  width: 100%;
  margin-bottom: 5px;
}
.success-btns--collect {
  padding: 16px 32px 16px 32px;
  gap: 10px;
  border-radius: 8px;
  border: 2px;
 background-color: $blue-dim;
  color: $white;
  width: 70%;
}
.success-btns--sell {
  padding: 16px 32px 16px 32px;
  gap: 10px;
  border-radius: 8px;
  border: 2px;
  background-color: $white;
  color:  $blue-dim;
  border: 2px solid $blue-dim;
}

.picklezone-setting-card{
    display: flex;
    width: 100%;
    padding: 8px 16px 8px 8px;
    align-items: center;
    gap: 16px;
    flex-direction: row;
    border-radius: 12px;
    background: #FFF;
    position: relative;
    //box-shadow: 0px 13px 10px -8px rgba(0, 0, 0, 0.10);

  &.picklezone-setting-card--selected {
    border: 1px solid $accent;
  }

  &.picklezone-settings-card--active {
    cursor: pointer;
  }

  &.picklezone-settings-card--disabled {
    pointer-events: none;
    opacity: 0.7;
  }
}

.container-text-for-collectibles{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 5px;

}

.row-text-for-collectibles{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.gap-for-icons{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.wrapper-for-picklezone-card{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.id-of-selector{
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    background: $accent;
    
    position: absolute;
    right: 8px;
    top: 8px;
    border-radius: 1000px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    color: #FFF;
    text-align: center;
    font-family: "Roboto";
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.field-card  {
  width: 100vw;
  height: 94%;
  position: absolute;
  top: 0;
  //left: 40%;
  z-index: 0;
  object-fit: cover;

  &.field-card--timeout {
    filter: grayscale(100%);
  }
}

.game-start-button-container {
  width: 100vw;
  height: 100vh;
  z-index: 900;
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(1, 1, 1, 0.2);
  visibility: visible;

  &.light {
    background-color: transparent;
  }

  &.hidden {
    visibility: hidden;
  }
}

.game-start-button {
  background-image: url("../../assets/ball-start.png");
  width: 187px;
  height: 187px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;

  .game-start-button--icon {
    background-image: url("../../assets/start-btn.svg");

    width: 104px;
    height: 108px;
    z-index: 100;
    margin-left: 24px;
    margin-bottom: 4px;
    position: relative;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    overflow: hidden;
  }
  .game-start-button--icon:active {
    transform: translateY(4px);
  }

  .game-start-button--sleep-time {
    color: $white;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
  }


}


/// personal info
.person-info {
  z-index: 1000;
  width: 100%;

  &.container-main {
    //width: auto;
    width: 100%;
    padding: 20px;
  }
}

.person-info__container {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  position: relative;

  border-radius: 24px;
  background: #FFF;
  box-shadow: 0px 40px 80px 0px rgba(0, 0, 0, 0.40);
  margin-bottom: 10px;

  &.container-main {
    //width: 320px;
    margin-bottom: 100px;
    max-width: 320px;
    margin-right: auto;
    margin-left: auto;
  }

  &.editing {
    padding: 20px 10px 10px 10px;
  }
}

.person-info__close {
  position: absolute;
  top: 20px;
  right: 20px;
}
.person-info__cards {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;;
  align-items: flex-start;
  gap: 10px;
}
.person-info__card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.person-info__img-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.person-info__link {
  color: $green-primary;

  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
}
.person-info__link:hover{
  opacity: 0.7;
  transition: all 0.8s;
}

.person-info__text-title {
  color: $black-soft;
  opacity: 0.4;

  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.person-info__text {
  color: $black;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.person-info__text-bold {
  color: $black;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
}
.person-info__text-normal {
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.person-info__img {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  object-fit: cover;

  input {
    background: none;
    outline: none;
    border: none;
    display: none;
  }
}
.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}
/////
.form-for-profile {
  // display: flex;
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  align-self: stretch;
  position: relative;

  border-radius: 16px;
  //border: 1px solid rgba(0, 0, 0, 0.20);
  background: #F5F5F5;
}

.label-for-profile {
  color: $accent;
  text-align: right;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.input-for-profile {
  background: none;
  outline: none;
  border: none;
  width: 85%;
  position: relative;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $black;
  //text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.input-error-text {
  color: $red;
}

.wrapper-for-crypto-wallet{
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 10px;
}
.form-for-profile-img {
  //background-image: url("../../assets/person1.png") ;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100px;
  width: 100px;
  border-radius: 100px;
}
.form-for-profile-img-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.form-for-profile-img input[type="file"] {
  //visibility: hidden;
  opacity: 0;
}

.task-card {
  display: flex;
  //width: 320px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 24px;
  background: #FFF;

  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);

  &.task-card-scrollable  {
    margin-top: 10px;
    height: calc(100vh - 350px);
    overflow-y: auto;
    &::-webkit-scrollbar-thumb {
      display: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
