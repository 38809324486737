.footer-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  //flex-wrap: wrap;
  //justify-content: space-evenly;
  //align-items: center;
  width: 100vw;
  height: auto;
  padding: 10px;
  //background-color: $white;
  border-top: 1px solid #FFFFFF26;
  box-shadow: 0 4px 80px 0 #00000040;
  border-top: 1px solid #FFFFFF1A;
  z-index: 9900;
  cursor: pointer;
  backdrop-filter: blur(20px);
  border-top: 1px solid rgba(0, 0, 0, 0.10);
  background: rgba(255, 255, 255, 0.60);

  &.footer-container__light {
    background: rgba(255, 255, 255, 1);
  }
}

.footer__cards-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.footer__controls-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
   width: 100%;
}

.footer__controls-start-btn {
  display: flex;
  //min-width: 320px;
  width: 100%;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  background: linear-gradient(85deg, #377B6D 46.3%, #6D9C63 100%);

  box-shadow: 0px 0px 20px 0px rgba(55, 123, 109, 0.60);

  color: $white;

  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.footer__control {
  padding: 10px;
  color: $green-accent;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 10px;
  background: $white;
  min-width: 122px;

  @media screen and (max-width: 580px) {
    padding: 6px;
    border-radius: 6px;
    min-width: 100px;
  }
  @media screen and (max-width: 260px) {
    min-width: 50px;
  }
}

.footer__stop_btn {
  display: flex;
  width: 46.4px;
  height: 46.4px;
  //padding: 16px 32px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #B91515;

  box-shadow: 0px 0px 40px 0px rgba(185, 21, 21, 0.60);

  @media screen and (max-width: 580px) {
    height: 38px;
    width: 38px;
    border-radius: 6px;
  }
}
.footer__stop_btn-center {
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: $white;
  @media screen and (max-width: 580px) {
     height: 18px;
     width: 18px;
    border-radius: 6px;
  }
}

.footer__cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15%;
  justify-content: center;
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    opacity: 0.6;
  }
}

.footer__cards {
  path {
    fill: rgba(0,0, 0 , 0.6);
  }
}

.footer__cards:hover .footer__icon {
    path {
      fill: $accent
     }
}
.footer__cards:hover .footer__text {
  color: $white;
}

.footer__cards.footer__cards--active .footer__icon {
  path {
    fill: $accent
  }
}
.footer__cards.footer__cards--active .footer__text {
  color: $white;
}

.footer__text {
  font-size: 12px;
  line-height: 18px;
  color: rgba($white, 0.6);
}


.disabled {
  //pointer-events: none;
  //opacity: 0.6;
}


.footer__control-icon {
  height: 20px;
  width: 20px;
}
